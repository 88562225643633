.container {
  background-color: #254e58;
  color: white;
}
.intro {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-left: 4.125vw;
  margin-right: 4.125vw;
  width: 360px;
  margin-top: 100px;
}
.intro-1 {
  font-size: 15px;
  color: white;
  text-align: left;
  font-family: "Open-Sans", sans-serif;
  font-weight: 300;
}
.title {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 70px;
  margin-top: 10px;
  text-align: left;
  line-height: 100%;
}
.name {
  color: salmon;
}
.intro-2 {
  margin-top: 10px;
  font-size: 15px;
  color: white;
  text-align: left;
  font-family: "Open-Sans", sans-serif;
  font-weight: 300;
}
.but {
  margin-top: 20px;
  background-color: #112d32;
  border: 2px solid white;
  color: white;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.but:hover {
  background-color: #254e58;
}
.socials {
  margin-top: 10px;
  text-align: left;
  letter-spacing: 20px;
  font-size: 30px;
}
.socials a:hover {
  color: #112d32;
}
.fa-linkedin {
  color: white;
  text-decoration: none;
}
.fa-github {
  color: white;
  text-decoration: none;
}
.fa-envelope {
  color: white;
  text-decoration: none;
}
.container-a {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
}
.about-w {
  background-color: #112d32;
  border: 20px solid #112d32;
  margin-left: 4.125vw;
  margin-right: 4.125vw;
  margin-top: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-align: justify;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 50px;
}
h3 {
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  color: white;
}
h4 {
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
}
button {
  text-align: center;
  background-color: black;
  color: white;
  border: 2px solid white;
  font-size: 15px;
  font-family: "Oswald", sans-serif;
}
.project-intro-container {
  margin-top: 30px;
  text-align: left;
  color: white;
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  margin: 10px;
  margin-bottom: 20px;
}
.project-text {
  font-size: 14px;
  margin-left: 10px;
}
.slides {
  text-align: center;
  background-color: #112d32;
  border: 8px solid #112d32;
  margin: 10px;
}
.Projects {
  text-align: center;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slides {
  position: relative;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slides .slick-prev {
  left: 5%;
}

.slides .slick-next {
  right: 5%;
}
.project-container {
  display: flex;
  flex-direction: column;
}
.project-i {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  border: 4px solid white;
  margin-bottom: 10px;
}
.img-des {
  color: white;
  margin-top: 10px;
  text-align: justify;
  font-family: "Open-sans", sans-serif;
  font-weight: 300;
  margin-right: 50px;
  margin-left: 50px;
  font-size: 15px;
}
a {
  color: #88bdbc;
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  .intro {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-left: 4.125vw;
    margin-right: 4.125vw;
    margin-top: 200px;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .intro-1 {
    font-size: 20px;
    color: white;
    text-align: center;
    font-family: "Open-Sans", sans-serif;
    font-weight: 300;
  }
  .title {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-size: 150px;
    margin-top: -10px;
    text-align: center;
    line-height: 100%;
  }
  .name {
    color: salmon;
  }

  .intro-2 {
    margin-top: 20px;
    font-size: 16px;
    color: white;
    text-align: center;
    font-family: "Open-Sans", sans-serif;
    font-weight: 300;
  }
  .but {
    padding: 12px 52px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
  }

  .socials {
    text-align: center;
    letter-spacing: 30px;
    margin-left: 40px;
  }
  .container-a {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    margin-left: 10.125vw;
    margin-right: 10.125vw;
    margin-top: 100px;
  }
  .about-w {
    margin-top: -30px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    text-align: justify;
    font-size: 14px;
  }
  .about-i {
    margin-top: -270px;
  }
}
@media screen and (min-width: 800px) {
  .project-container {
    display: flex;
    flex-direction: column;
  }
  .project-i {
    width: 50%;
  }
}

@media screen and (min-width: 1100px) {
  .project-container {
    display: flex;
    flex-direction: row;
  }
  .project-i {
    display: flex;
    margin-left: 12vw;
  }
  .img-des {
    color: white;
    text-align: justify;
    font-family: "Open-sans", sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    margin-right: 12vw;
  }
  .summary {
    display: flex;
  }
}
