.navbar {
  background-color: #254e58;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Oswald", sans-serif;
  font-weight: 400px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: white;
  justify-self: start;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: left;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  display: flex;
  align-items: center;
  float: right;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
}

.nav-links:hover {
  color: #88bdbc;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: white;
  font-size: 1.5rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #254e58;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
  }

  .nav-links:hover {
    color: #88bdbc;
    border-radius: 0;
  }
  .nav-links:active {
    color: #88bdbc;
  }

  /*.navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);*/

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: white;
    font-size: 1.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
